import { Injectable, inject } from '@angular/core';
import { ApiService, saveUrlObject } from '@mca/shared/util';
import {
  httpSystemEmails,
  httpSystemComponents,
  httpSystemLogs,
  httpSystemClearCache,
  httpSystemACHScheduled,
  httpSystemTransAllocate,
  httpSystemPortfolioSnap,
  httpSystemMcaAccess,
  httpSystemEmailConfig,
  httpSystemUpdateEmailConfig,
  httpSystemChangelog,
  httpSystemConfiguration,
  httpSystemBPAllocate,
  httpSystemChangelogAggregated,
} from '../infrastructure/system-http-endpoints';
import { Observable } from 'rxjs';
import { EmailConfig } from '../entities/email-config';
import { SystemConfig } from '@mca/shared/domain';
import { ChangelogRec } from '../entities/changelog';
import { getServiceWorker, ReplayWorkerMode, serviceWorkerClientUrlToken, ServiceWorkerCommand } from '@mca/shared/util/service-worker';
import { MessageService } from 'primeng/api';
import { MenuAccessService } from '@mca/auth/domain';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  private apiService = inject(ApiService);
  private serviceWorkerClientUrl = inject(serviceWorkerClientUrlToken, { optional: true }) ?? '';
  private messageService = inject(MessageService);
  private menuAccessService = inject(MenuAccessService);
  private router = inject(Router);

  replayMode = 'disabled' as ReplayWorkerMode;

  constructor() {
    this.watchServiceWorker();
  }

  getSystemEmails(status: string, startDate: string, endDate: string) {
    return this.apiService.get(httpSystemEmails(), {
      params: {
        status,
        start: startDate,
        end: endDate,
      },
    });
  }

  deleteSystemEmails(ids: string[]) {
    return this.apiService.delete(httpSystemEmails(), {
      params: {
        ids,
      },
    });
  }

  getSystemComponents() {
    return this.apiService.get(httpSystemComponents());
  }

  getSystemLog(component: string, severity: string, startDate: string, endDate: string) {
    return this.apiService.get(httpSystemLogs(), {
      params: {
        comp: component,
        level: severity,
        startDate,
        endDate,
      },
    });
  }

  deleteSystemLog(ids: number[]) {
    return this.apiService.delete(httpSystemLogs(), {
      params: {
        ids,
      },
    });
  }

  clearCache(): void {
    this.apiService.post(httpSystemClearCache()).subscribe(() => location.reload());
  }

  achScheduled(): void {
    this.apiService.post(httpSystemACHScheduled()).subscribe(() => location.reload());
  }

  allocateTransactions(): void {
    this.apiService.post(httpSystemTransAllocate()).subscribe(() => location.reload());
  }

  allocateBPAllocate(): void {
    this.apiService.post(httpSystemBPAllocate()).subscribe(() => location.reload());
  }

  portfolioSnapp(): void {
    this.apiService.post(httpSystemPortfolioSnap()).subscribe(() => location.reload());
  }

  mcaAccess(params: { mcaid: number; user: number; startDate: string; endDate: string }) {
    return this.apiService.get(httpSystemMcaAccess(), { params });
  }

  getSystemEmailConfig(): Observable<EmailConfig[]> {
    return this.apiService.get(httpSystemEmailConfig());
  }

  updateSystemEmailConfig(tag: string, params: EmailConfig) {
    return this.apiService.put(httpSystemUpdateEmailConfig(tag), params);
  }

  getChangelog(module: string, recId: number) {
    return this.apiService.get<ChangelogRec[]>(httpSystemChangelog(module, recId));
  }

  getChangelogAggregated(params: { rec_type: string[]; start_date: string; end_date: string }) {
    return this.apiService.get<ChangelogRec[]>(httpSystemChangelogAggregated(), { params });
  }

  getConfiguration() {
    return this.apiService.get<SystemConfig>(httpSystemConfiguration());
  }

  updateConfiguration(params: SystemConfig) {
    return this.apiService.put(httpSystemConfiguration(), params);
  }

  startDebugRecord() {
    getServiceWorker(this.serviceWorkerClientUrl)?.then((registration?: ServiceWorkerRegistration) => {
      console.warn(this.router.url);
      registration?.active?.postMessage({ command: ServiceWorkerCommand.REPLAY_RECORD, initialUrl: this.router.url });
      window.location.reload();
    });
  }

  stopAndSaveDebugRecord() {
    getServiceWorker(this.serviceWorkerClientUrl)?.then((registration?: ServiceWorkerRegistration) => {
      registration?.active?.postMessage({ command: ServiceWorkerCommand.REPLAY_STORE });
    });
  }

  stopDebugReplay() {
    getServiceWorker(this.serviceWorkerClientUrl)?.then((registration?: ServiceWorkerRegistration) => {
      registration?.active?.postMessage({ command: ServiceWorkerCommand.REPLAY_STOP });
    });
  }

  loadDebugRecord() {
    getServiceWorker(this.serviceWorkerClientUrl)?.then((registration?: ServiceWorkerRegistration) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'application/json';

      input.addEventListener('change', async event => {
        const file = (event.target as HTMLInputElement).files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = async e => {
          try {
            const fileContent = JSON.parse(e.target?.result as string);
            registration?.active?.postMessage({ command: ServiceWorkerCommand.REPLAY_LOAD, fileContent });
          } catch (error) {
            this.messageService.add({ severity: 'error', summary: 'Invalid file format' });
          }
        };
        reader.readAsText(file);
      });

      input.click();
    });
  }

  private watchServiceWorker() {
    // service workers only available with https
    navigator.serviceWorker?.addEventListener('message', event => {
      switch (event.data.command) {
        case ServiceWorkerCommand.REPLAY_STORE:
          saveUrlObject(event.data.message, 'debug.json');
          break;
        case ServiceWorkerCommand.REPLAY_LOAD:
          if (window.location.href === event.data.message) {
            window.location.reload();
          } else {
            window.location.href = event.data.message;
          }
          break;
        case ServiceWorkerCommand.REPLAY_MODE:
          this.replayMode = event.data.message;
          this.menuAccessService.updateAllowedItems();
          break;
      }
    });
  }
}
